import { React, useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { EventTracker } from "../../util/analytics-ag";

const faqData = [
  {
    question: "What are the hours of operation?",
    answer: "City Nights operates every weekend as follows:\nRitmo Latino (Friday Nights): 10:00 PM to 2:00 AM\nClubX (Saturday Nights): 9:30 PM to 2:00 AM."
  },
  {
    question: "What is the age requirement for entry?",
    answer: "We are an 18+ venue. A valid Government-Issued ID is required for entry."
  },
  {
    question: "What is Club X’s Dress Code?",
    answer: "Guests are encouraged to dress to impress, but casual attire is allowed. No tank tops, sports apparel, or shorts."
  },
  {
    question: "Where do I park?",
    answer: "Street parking is available and free in San Francisco after 6 PM. Paid lots are located on both sides of the venue and diagonally across from the venue."
  },
  {
    question: "What is the cover charge?",
    answer: "The cover charge is $20 per guest. Skip-the-line tickets are available for $30 per guest. We also offer booth rentals and VIP packages."
  },
  {
    question: "How do I purchase tickets for an event?",
    answer: "Paying cash? Buy your ticket at the door. Using Apple Pay or a credit card? Buy tickets at www.sfclubs.com."
  },
  {
    question: "What if I don’t receive my confirmation email?",
    answer: "We can look you up by your name at the door, or contact us at info@715harrison.com and we will forward your ticket to the correct email address."
  },
  {
    question: "Can I get a refund if I can’t attend the event?",
    answer: "As per our terms and conditions, all sales are final; no refunds are given for any of our events."
  },
  {
    question: "Do I need to print my ticket?",
    answer: "No need to print your ticket. We can scan you in with your digital ticket at the door."
  },
  {
    question: "Do you offer a Guest List?",
    answer: "No guest list is available. If you have a large group, please reach out to info@715harrison.com; we can assist you with pricing and reservations."
  },
  {
    question: "Do you have a coat-check?",
    answer: "Yes, coat check is available for all guests, located next to the entry on the left-hand side when you walk into the venue."
  },
  {
    question: "What is your refund policy?",
    answer: "All ticket purchases are final. No refunds, exchanges, or cancellations are allowed."
  },
  {
    question: "How do I book a private event?",
    answer: "For space rental inquiries, please contact events@715harrison.com, and our special events team will reach out to you within 24 hours."
  },
  // {
  //   question: "How do I book a Private Event",
  //   answer: "For space rental inquiries, please contact events@715harrison.com, and our special events team will reach out to you within 24 hours. Click this link for more information:",
  //   link: "https://715harrisoneventspace.com",
  //   linkName: "715 Harrison Events Space"
  // }
];

export default function Faq({ id, isDark = false }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div id={id} className={`${isMobile ? 'px-10' : 'px-[80px]'} pb-[100px] pt-[50px]`}>
      <h1 style={{ fontWeight: 900 }} className={`text-center text-4xl font-semibold mb-4 ${isDark ? 'text-white' : ''}`}>
        FAQ
      </h1>
      <div className="flex flex-col items-center">
        {faqData.map((faq, index) => (
          <div key={index} className="border-b w-full max-w-[1240px] mb-2">
            <div
              className="flex justify-between items-center py-4 cursor-pointer"
              onClick={() => {
                toggleFaq(index);
                EventTracker({
                  category: 'FAQ',
                  action: faq?.question,
                  label: faq?.answer
                });
              }}
            >
              <h4 className={`text-sm md:text-base ${isDark ? 'text-white' : 'text-black'}`}>{faq.question}</h4>
              {openFaq === index ? (
                <IoIosArrowDown color={isDark ? 'white' : 'black'} className="font-bold w-4 h-4 md:w-6 md:h-6" />
              ) : (
                <IoIosArrowForward color={isDark ? 'white' : 'black'} className="font-bold w-4 h-4 md:w-6 md:h-6" />
              )}
            </div>
            {openFaq === index && (
              <p className={`text-sm md:text-base mb-4 ${isDark ? 'text-white' : 'text-black'}`}>{faq.answer} {faq?.link ? <a target="_BLANK" rel="noreferrer" href={faq.link}>{faq?.linkName}</a> : ''}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
