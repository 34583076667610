import moment from "moment";
import { React } from "react";

export default function EventCard({event}) {

  return (
    <div className="h-[356px] w-[343px] overflow-hidden rounded-[5px] m-[14px] cursor-pointer event-card">
      <img
        src={event?.promo?.banner || event?.promo?.story}
        alt="Event"
        className="w-[343px] h-[194px] object-cover object-center"
      />
      {/* Bottom Section */}
      <div className="w-[343px] h-[146px] flex bg-white pb-[38px] pt-[24px]">
        {/* Date Section */}
        <div className="flex flex-col items-center justify-center mx-[11px] ">
          <span className="text-[10px] font-normal">{moment(event?.start_date).format('MMM')}</span>
          <span className="text-3xl font-bold">{moment(event?.start_date).format('DD')}</span>
          <span className="text-[10px] font-light">{moment(event?.start_date).format('ddd')}</span>
        </div>
        {/* Event Info Section */}
        <div className="flex flex-col pl-[15px] pr-[34px] border-l-black border-l-[2px]">
          <span className="text-[20px] font-bold mb-[8px]">{event?.name}</span>
          <span className="text-[14px] font-medium">{event?.organization?.name} | {event?.organization?.age} | {moment(event?.start_date).format('MMM D, YYYY')} | {event?.organization?.city} {event?.organization?.state}</span>
        </div>
      </div>
    </div>
  );
}
